import React from "react";
import PropTypes from "prop-types";
import { makeStyles } from "@material-ui/core/styles";
import Button from "@material-ui/core/Button";
import Avatar from "@material-ui/core/Avatar";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemAvatar from "@material-ui/core/ListItemAvatar";
import ListItemText from "@material-ui/core/ListItemText";
import DialogTitle from "@material-ui/core/DialogTitle";
import Dialog from "@material-ui/core/Dialog";
import { blue } from "@material-ui/core/colors";
import IconButton from "@material-ui/core/IconButton";
import CloseIcon from "@material-ui/icons/Close";
const useStyles = makeStyles({
  avatar: {
    backgroundColor: blue[100],
    color: blue[600],
  },
});

function SimpleDialog(props) {
  const classes = useStyles();
  const {
    onClose,
    selectedValue,
    open,
    children,
    fullScreen,
    callback,
  } = props;

  const handleClose = () => {
    onClose(selectedValue);
  };

  const handleListItemClick = (value) => {
    onClose(value);
  };

  return (
    <Dialog
      fullScreen={fullScreen}
      onClose={handleClose}
      aria-labelledby="simple-dialog-title"
      open={open}
    >
      {children}
    </Dialog>
  );
}

SimpleDialog.propTypes = {
  onClose: PropTypes.func.isRequired,
  open: PropTypes.bool.isRequired,
  selectedValue: PropTypes.string.isRequired,
};

export default function SimpleDialogOpen(props) {
  const { openProp, callback, children, icon, fullScreen } = props;
  const [open, setOpen] = React.useState(openProp);
  const handleClickOpen = () => {
    setOpen(true);
    callback(true);
  };

  const handleClose = (value) => {
    setOpen(false);
    callback(false);
  };

  return (
    <div>
      <SimpleDialog
        fullScreen={fullScreen}
        open={openProp}
        onClose={handleClose}
      >
        <IconButton
          onClick={() => handleClose()}
          color="primary"
          aria-label="cerrar"
        >
          <CloseIcon />
        </IconButton>
        <div style={{ backgroundColor: "black", flex: 1 }}>{children}</div>
      </SimpleDialog>
    </div>
  );
}
