export function useGetBrandImage(brand, carsCatalogue) {
  if (carsCatalogue.brands[brand] != undefined) {
    return carsCatalogue.brands[brand].brandImage;
  }
  return false;
}
export function useGetBrandName(brand, carsCatalogue) {
  if (carsCatalogue.brands[brand] != undefined) {
    return carsCatalogue.brands[brand].brand;
  }
  return false;
}

export function useGetColorValue(color, carsCatalogue) {
  if (color == "") {
    return "#FFFFFF";
  }
  if (carsCatalogue.colors[color] != undefined) {
    return carsCatalogue.colors[color].value;
  }
  return false;
}

export function useGetColorName(color, languageObject) {
  if (color == "") {
    return languageObject.generalData.colors["empty"];
  }
  return languageObject.generalData.colors[color];
}

export function useSetStatusText(status, languageObject, carsCatalogue) {
  if (carsCatalogue.status[status] != undefined) {
    return languageObject.generalData.status[status].name;
  }
  return false;
}

export function useGetVehicleNotesText(notes, languageObject) {
  let texts = [];
  let count = 0;
  for (var i in notes) {
    count++;
    console.log(notes[i]);
    texts.push(notes[i].text);
  }

  console.log(notes);
  if (count > 0) {
    console.log("array notes");
    const notesText = texts.join(", ");
    return { text: notesText, count: count, array: Object.values(notes) };
  }
  return {
    text: languageObject.authorizeUserdViews.vehiclesDetail.placeholderNotes,
    count: 0,
    array: [],
  };
}

export function useCountPhotos(photos) {
  if (photos == null || photos == undefined) {
    return 0;
  }
  return Object.values(photos).length;
}

export function useValidateVehicleData(vehicle, languageObject) {
  var texts = languageObject.authorizeUserdViews.vehiclesAdd;
  var arrayAtributesRequired = [
    "card",
    "key",
    "color",
    "brand",
    "startTime",
    "status",
    "plateNumber",
  ];

  var arrayErrors = [];
  var status = true;
  for (var i in vehicle) {
    if (arrayAtributesRequired.indexOf(i) >= 0) {
      if (vehicle[i] == "" || vehicle[i] == 0) {
        arrayErrors.push({
          message: texts.checkRequiredData + "(" + i + ")",
          valid: false,
        });
        status = false;
      }
    }
  }
  return { status: status, list: arrayErrors };
}

export function updateChangesList(vehicle, changeObject, userID) {
  let changes = vehicle.changes;
  const atributesToSaveChanges = [
    "card",
    "key",
    "brand",
    "color",
    "plateNumber",
    "status",
    "place",
    "zone",
    "closed",
  ];
  const atributesToAdd = ["notes", "photos"];

  for (var i in changeObject) {
    if (atributesToSaveChanges.indexOf(i) >= 0) {
      var date = Math.round(Date.now() / 1000);
      var ramdom = Math.round(Math.random() * 100);
      changes[date + "-" + ramdom] = {
        date: date,
        userID: userID,
        atribute: i,
        previous: vehicle[i] == undefined ? "" : vehicle[i],
        newValue: changeObject[i],
      };
    } else if (atributesToAdd.indexOf(i) >= 0) {
      var date = Math.round(Date.now() / 1000);
      var ramdom = Math.round(Math.random() * 100);
      changes[date + "-" + ramdom] = {
        date: date,
        userID: userID,
        atribute: i,
        previous: "",
        newValue: i + " ADD",
      };
    } else if (i == "payment") {
      var date = Math.round(Date.now() / 1000);
      var ramdom = Math.round(Math.random() * 100);
      var previous = Object.values(vehicle[i].list);
      var newValue = Object.values(changeObject[i].list);
      var add = "ADD";
      if (previous.length == newValue.length) {
        add = "CHANGE";
      }
      changes[date + "-" + ramdom] = {
        date: date,
        userID: userID,
        atribute: i,
        previous: "",
        newValue: i + " " + add,
      };
    }
  }
  return changes;
}

export function useArrayStatusNormalFlow(languageObject) {
  return {
    arriving: {
      nextStatus: "scanned",
      nextStatusText: languageObject.generalData.status["scanned"].name,
      nextStatusPlus1: "parking",
      nextStatusPlus1Text: languageObject.generalData.status["parking"].name,
    },
    scanned: {
      nextStatus: "parking",
      nextStatusText: languageObject.generalData.status["parking"].name,
      nextStatusPlus1: "parked",
      nextStatusPlus1Text: languageObject.generalData.status["parked"].name,
    },
    parking: {
      nextStatus: "parked",
      nextStatusText: languageObject.generalData.status["parked"].name,
      nextStatusPlus1: "recovering",
      nextStatusPlus1Text: languageObject.generalData.status["recovering"].name,
    },
    parked: {
      nextStatus: "recovering",
      nextStatusText: languageObject.generalData.status["recovering"].name,
      nextStatusPlus1: "platform",
      nextStatusPlus1Text: languageObject.generalData.status["platform"].name,
    },
    requested: {
      nextStatus: "recovering",
      nextStatusText: languageObject.generalData.status["recovering"].name,
      nextStatusPlus1: "platform",
      nextStatusPlus1Text: languageObject.generalData.status["platform"].name,
    },
    recovering: {
      nextStatus: "platform",
      nextStatusText: languageObject.generalData.status["platform"].name,
      nextStatusPlus1: "taken",
      nextStatusPlus1Text: languageObject.generalData.status["taken"].name,
    },
    platform: {
      nextStatus: "taken",
      nextStatusText: languageObject.generalData.status["taken"].name,
      nextStatusPlus1: "none",
      nextStatusPlus1Text: "",
    },
    taken: {
      nextStatus: "none",
      nextStatusText: "",
      nextStatusPlus1: "none",
      nextStatusPlus1Text: "",
    },
    canceled: {
      nextStatus: "none",
      nextStatusText: "",
      nextStatusPlus1: "none",
      nextStatusPlus1Text: "",
    },
    unpaid: {
      nextStatus: "paid",
      nextStatusText: languageObject.generalData.paymentStatus["paid"].name,
      nextStatusPlus1: "none",
      nextStatusPlus1Text: "",
    },
    paid: {
      nextStatus: "none",
      nextStatusText: "",
      nextStatusPlus1: "none",
      nextStatusPlus1Text: "",
    },
  };
}
