import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { makeStyles } from "@material-ui/core/styles";
import { Redirect } from "react-router-dom";
import { useLocation } from "react-router-dom";

import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import Button from "@material-ui/core/Button";
import { useForm } from "react-hook-form";
import EventIcon from "@material-ui/icons/Event";
//import UserPaymentMethodsAdd from "../UserProfile/UserPaymentMethodsAdd";
import VerifiedUserIcon from "@material-ui/icons/VerifiedUser";
import axios from "axios";
import LinearProgress from "@material-ui/core/LinearProgress";
import { setAmountToPay } from "../../../../data-store/actions/vehicle-actions";
import { Paper } from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    width: "100%",
    backgroundColor: "black",
  },
  paper: {
    padding: "20px",
    backgroundColor: "#283747",
    textAlign: "left",
    minHeight: "400px",
    overflowY: "scroll",
  },
  buttons: {
    margin: 30,
    width: "250px",
    color: "black",
  },
}));

export default function YappyPayment(props) {
  const { callback, locationId, businessId, vehicleId, card } = props;
  const dispatch = useDispatch();

  const classes = useStyles();
  const { register, handleSubmit, errors } = useForm();
  const [sending, setSending] = useState(false);
  const [loading, setLoading] = useState(false);
  const [errorMessage, seterrorMessage] = useState(false);
  const [successURL, setSuccessURL] = useState(false);

  const amountToPay = useSelector((state) => state.vehicle.amountToPay);
  const dateToPay = useSelector((state) => state.vehicle.dateToPay);
  const amountPayed = useSelector((state) => state.vehicle.amountPayed);
  const statusPayment = useSelector((state) => state.vehicle.statusPayment);

  const location = useLocation();
  const { hash, pathname, search } = location;
  console.log("query", pathname, search);

  // const businessId = query.get("business");
  // const locationId = query.get("location");
  // const card = query.get("card");

  useEffect(() => {
    // yappy.setButton(false, "formId", "brand");
    // //dispatch event
    // window.document.dispatchEvent(
    //   new Event("DOMContentLoaded", {
    //     bubbles: true,
    //     cancelable: true,
    //   })
    // );
  }, []);

  function onSubmit(data) {
    // setCardNumber(data.cardNumber);
    // setCardOwner(data.cardOwner);
    setSending(true);
    seterrorMessage(false);
    // console.log("data", data);
    // console.log("data", cardNumber);
    // console.log("data", sending);

    console.log("tokenizar", data);
    sendPaymetYappy(data);
  }

  function sendPaymetYappy(data) {
    const payment = {
      total: amountToPay,
      subtotal: amountToPay,
      shipping: 0.0,
      discount: 0.0,
      taxes: "0.00",
      orderId: "1234",
      successUrl:
        "https://www.smartvalet.app" + pathname + search + "/success=true",
      failUrl:
        "https://www.smartvalet.app" + pathname + search + "/success=false",
      tel: "",
      domain: "https://www.smartvalet.app",
    };

    var jsonDatos = {
      businessId: businessId,
      locationId: locationId,
      vehicleId: vehicleId,
      date: dateToPay,
      payment: payment,
    };

    console.log("payment", jsonDatos);

    console.log(jsonDatos);

    var headers = {
      headers: { "Access-Control-Allow-Origin": true },
    };

    axios
      .post(
        "https://us-central1-softicade.cloudfunctions.net/setYappyPayment",
        jsonDatos,
        headers
      )
      .then((res) => {
        console.log("respuesta", res);

        setSending(false);

        if (res.data.success === true) {
          console.log(
            "respuesta",
            "Aprobada ahora redirige a la web de Yappy" + res.data.url
          );
          seterrorMessage("Aprobada ahora redirige a la web de Yappy");
          setSuccessURL(res.data.url);
          window.open(res.data.url, "_blank");
        } else {
          seterrorMessage(
            "Transacción declinada" + JSON.stringify(res.data.error.message)
          );
        }
      })
      .catch((error) => {
        console.log("respuesta", JSON.stringify(error));
        setSending(false);
        seterrorMessage("Error procesando el pago");
        var errorTexto = "";
        if (error.responseJSON !== undefined) {
          for (var i in error.responseJSON.details) {
            errorTexto =
              errorTexto + error.responseJSON.details[i].message + " - ";
          }
        }
      });
  }

  return (
    <Grid
      container
      direction="row"
      style={{
        height: "auto",
        width: "100%",
      }}
    >
      {amountToPay === 0 && amountPayed > 0 && statusPayment === 1 && (
        <Typography
          color="primary"
          variant="h4"
          style={{ marginTop: 40, padding: 10 }}
        >
          Pago éxitoso, diríjase a retirar su vehículo.
        </Typography>
      )}

      <Button onClick={() => sendPaymetYappy()}>YAPPY BOTÓN</Button>
    </Grid>
  );
}
