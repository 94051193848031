import React from "react";
import { Switch, Route } from "react-router-dom";

import LayoutContainer from "./components/LayoutContainers/LayoutContainer";

const Root = () => (
  <Switch>
    <Route exact path="/" component={LayoutContainer} />
    <Route exact path="/:route" component={LayoutContainer} />
  </Switch>
);

export default Root;
