import React from "react";
import ReactDOM from "react-dom";

import store from "./data-store/configureStore";

import "./index.css";
import App from "./App";
import registerServiceWorker from "./registerServiceWorker";

//	var initialState = {events:{events:{"evento_1":{"nombreEvento":"prueba","idEvento":1},"evento_2":{"nombreEvento":"prueba2","idEvento":2}}}};
ReactDOM.render(<App store={store} />, document.getElementById("root"));
registerServiceWorker();
