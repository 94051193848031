
import {combineReducers} from 'redux';
import valet from './valet-reducer';
import auth from './auth-reducer';
import vehicle from './vehicle-reducer';

export default combineReducers({
	valet,
	auth,
	vehicle
});
