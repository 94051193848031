import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import Grid from "@material-ui/core/Grid";
import Button from "@material-ui/core/Button";

import Typography from "@material-ui/core/Typography";
import Hidden from "@material-ui/core/Hidden";

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },
  buttons: {
    margin: 30,
    width: "250px",
    color: "black",
  },
  buttons2: {
    margin: 30,
    width: "250px",
    color: "white",
  },
}));

export default function Banner(props) {
  const { bigImage, smallImage, height, onClickUrl } = props.componentConfig;

  //trackEvent({ event: { event: "PageView", page: eventId } });

  return (
    <div>
      <Button
        href={onClickUrl}
        style={{ width: "100%", height: "auto", margin: 0, padding: 0 }}
        target="_black"
      >
        <Hidden only={["md", "lg", "xl"]}>
          <img
            src={
              smallImage === undefined || smallImage === ""
                ? bigImage
                : smallImage
            }
            alt="banner"
            height={height === "" ? height : "auto"}
            width={"100%"}
          />
        </Hidden>
        <Hidden only={["sm", "xs"]}>
          <img
            src={bigImage}
            alt="banner"
            height={height === "" ? height : "auto"}
            width={"100%"}
          />
        </Hidden>
      </Button>
    </div>
  );
}

Banner.displayName = "Banner";

export const componentDescription = {
  componentConfig: {
    bigImage: {
      label: "Imagen de banner",
      errorMessage: "",
      fieldName: "bigImage",
      type: "photo",
      defaultValue: "",
      required: true,
      regex: "",
      textSize: "h6",
      route: "viewsImages/Banner/",
    },
    smallImage: {
      label: "Imagen de banner (pantallas pequeñas)",
      errorMessage: "",
      fieldName: "smallImage",
      type: "photo",
      defaultValue: "",
      required: true,
      regex: "",
      textSize: "h6",
      route: "viewsImages/Banner/",
    },
    height: {
      type: "text",
    },
    onClickUrl: {
      type: "text",
    },
  },
};
