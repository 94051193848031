import { createStore, applyMiddleware } from "redux";
import thunk from "redux-thunk";
import logger from "redux-logger";
import rootReducer from "./reducers";
import siteConfig from "../siteConfig/viewsConfig.json";

const store = createStore(
  rootReducer,
  {
    vehicles: null,
    valet: {
      siteConfig: siteConfig,
    },
  },
  applyMiddleware(thunk, logger)
);

export default store;
