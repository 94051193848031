import * as types from "./action-types";
import { firestore, users, auth, catalogue } from "../../firebase/firebasejs";
import { Strings } from "../../Strings";
import store from "../configureStore";

import { sumarSegAFecha } from "../../services/utils";

//###################################################################################
export function setLanguage(language) {
  return (dispatch) => {
    localStorage.setItem("language", language);
    dispatch({
      type: types.LANGUAGE,
      language: language,
      languageObject: Strings[language],
    });
  };
}
//###################################################################################
export function userAccount() {
  //getDataFromFirestore();
  //getDataFromFirestoreCollection();

  console.log("Monitorear cuenta");
  return (dispatch) => {
    var userData = null;
    auth.onAuthStateChanged((user) => {
      if (user) {
        if (user != null) {
          var name = user.displayName;
          var email = user.email;
          var photoUrl = user.photoURL;
          var uid = user.uid;
          var IDCompetidor = uid; // The user's ID, unique to the Firebase project. Do NOT use
          // this value to authenticate with your backend server, if
          // you have one. Use User.getToken() instead.
        }

        var DatosUsuario = firestore.collection(users).doc(uid);
        return DatosUsuario.onSnapshot((userData) => {
          if (userData != null && userData.exists) {
            let userAccountFinal = userData.data();

            dispatch({
              type: types.USER_ACCOUNT,
              userAccount: userAccountFinal,
              userID: uid,
            });
          }
        });
      } else {
        return dispatch({
          type: types.USER_ACCOUNT,
          userAccount: null,
          userID: null,
        });
      }
    });
  };
}
//###################################################################################
export function loadingFunc() {
  return (dispatch) => {
    dispatch({
      type: types.LOADING,
      loading: loading,
    });
  };
}
//###################################################################################
export function getCatalogueFunc() {
  console.log("catalogue");
  var catalogueRef = firestore.collection(catalogue);
  return (dispatch) => {
    return catalogueRef
      .get()
      .then((querySnapshot) => {
        var catalogueFinal = {};
        querySnapshot.forEach(function (doc) {
          catalogueFinal[doc.id] = doc.data();
        });
        dispatch({
          type: types.CATALOGUE,
          catalogue: catalogueFinal,
        });
      })
      .catch(function (error) {
        console.log("Error getting documents: ", error);
      });
  };
}
