import React from "react";
import { useSelector } from "react-redux";
import {
  createMuiTheme,
  makeStyles,
  ThemeProvider,
} from "@material-ui/core/styles";
import { useTracking } from "react-tracking";
import LayoutResolver from "./LayoutResolver";

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },
}));

export function resolvePage(route, siteConfig) {
  if (route === undefined) {
    return siteConfig.containers.home;
  }

  if (siteConfig.containers[route] !== undefined) {
    return siteConfig.containers[route];
  }

  return siteConfig.containers.home;
}

export default function LayoutContainer({ match }) {
  const { route } = match.params;
  const siteConfig = useSelector((state) => state.valet.siteConfig);
  const classes = useStyles();
  const { trackEvent } = useTracking();

  //trackEvent({ event: { event: "PageView", page: route } });

  let pageToShow = resolvePage(route, siteConfig);

  if (pageToShow) {
    return <LayoutResolver page={pageToShow} />;
  }

  return null;
}
