import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import Grid from "@material-ui/core/Grid";
import Box from "@material-ui/core/Box";
import { connect, dispatch, useSelector } from "react-redux";
//import { useTracking } from "react-tracking";
import Typography from "@material-ui/core/Typography";

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },
}));

export default function GridBoxInfo(props) {
  const {
    boxesArray,
    boxXs,
    boxSm,
    textColor,
    borderColor,
    bgColor,
    imageSize,
    boxHeight,
  } = props.componentConfig;
  return (
    <Grid
      container
      direction="row"
      alignItems="center"
      justify="space-around"
      style={{ backgroundColor: bgColor, padding: 20 }}
    >
      {boxesArray.map((box) => (
        <Grid item xs={boxXs} sm={boxSm} style={{ padding: 5 }}>
          <Box
            borderColor={borderColor}
            border={2}
            style={{ padding: 20, height: boxHeight }}
          >
            <div
              style={{
                width: "100%",
                alignContent: "left",
                alignItems: "left",
              }}
            >
              <img
                src={box.image}
                style={{ display: "block", margin: "0" }}
                alt={box.alt}
                height={`${imageSize}px`}
              />
            </div>

            <Typography
              variant="body2"
              style={{ color: textColor, textAlign: "justify", marginTop: 20 }}
            >
              {box.text}
            </Typography>
          </Box>
        </Grid>
      ))}
    </Grid>
  );
}
