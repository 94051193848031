import React from "react";
import { useSelector } from "react-redux";
import { makeStyles } from "@material-ui/core/styles";
import Grid from "@material-ui/core/Grid";
import { useTracking } from "react-tracking";
import Typography from "@material-ui/core/Typography";
import { CircularProgress, LinearProgress } from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },
}));

export default function Loading(match) {
  return (
    <div
      style={{
        flex: 1,
        height: "1000px",
        backgroundColor: "#ffffff",
        opacity: 1,
      }}
    >
      <LinearProgress color="primary" />
      <Grid
        container
        direction="column"
        alignItems="center"
        alignContent="center"
      >
        <Grid
          item
          style={{
            width: "auto",
            margin: "50px",
            marginTop: "10%",
          }}
          alignItems="center"
          alignContent="center"
        >
          <img
            src="https://firebasestorage.googleapis.com/v0/b/softicade.appspot.com/o/Logo%2FlogoWords.png?alt=media&token=63bf2d1c-0203-4b2b-98ea-03667c533fca"
            alt="smartValet"
            height={100}
          />
        </Grid>
        <CircularProgress color="secondary" />
      </Grid>
    </div>
  );
}
