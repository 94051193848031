import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import Grid from "@material-ui/core/Grid";
import { connect, dispatch, useSelector } from "react-redux";
//import { useTracking } from "react-tracking";
import Typography from "@material-ui/core/Typography";
import { Button } from "@material-ui/core";
import ContactForm from "../../../ConctactForm/ContactFormFullData";

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },
}));

export default function Footer(props) {
  const {
    title,
    text,
    padding,
    bgColor,
    buttonColor,
    buttonTextColor,
  } = props.componentConfig;
  return (
    <Grid
      container
      direction="row"
      style={{
        backgroundColor: bgColor,
        width: "100%",
        padding: padding,
      }}
    >
      <Grid item xs={12} sm={6} style={{ paddingLeft: "20px" }}>
        <Typography variant="h3" style={{ color: "white", textAlign: "left" }}>
          {title}
        </Typography>
        <Typography
          variant="subtitle1"
          style={{ color: "white", textAlign: "left" }}
        >
          {text}
        </Typography>
      </Grid>
      <Grid item xs={12} sm={6} style={{ verticalAlign: "center" }}>
        <ContactForm
          fieldObject={[
            {
              label: "Nombre",
              errorMessage: "Campo Requerido",
              fieldName: "name",
              type: "text",
              defaultValue: "",
              required: true,
              regex: "",
              textSize: "h6",
            },
            {
              label: "Nombre de la empresa",
              errorMessage: "Campo Requerido",
              fieldName: "business",
              type: "text",
              defaultValue: "",
              required: true,
              regex: "",
              textSize: "h6",
            },
            {
              label: "Correo",
              errorMessage: "Campo Requerido",
              fieldName: "email",
              type: "text",
              defaultValue: "",
              required: true,
              regex: "",
              textSize: "h6",
            },
            {
              label: "Teléfono",
              errorMessage: "Campo Requerido",
              fieldName: "phone",
              type: "text",
              defaultValue: "",
              required: true,
              regex: "",
              textSize: "h6",
            },
            {
              label: "Que necesitas?",
              errorMessage: "Campo Requerido",
              fieldName: "need",
              type: "text",
              defaultValue: "",
              required: true,
              regex: "",
              textSize: "h6",
            },
          ]}
          buttonText="Enviar Información a Smart Valet"
          buttonColor={buttonColor}
          buttonTextColor={buttonTextColor}
          inputColor="#FFFFFF"
          direcionn="row"
        />
      </Grid>
    </Grid>
  );
}
