import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import Grid from "@material-ui/core/Grid";
import Box from "@material-ui/core/Box";
import { connect, dispatch, useSelector } from "react-redux";
//import { useTracking } from "react-tracking";
import Typography from "@material-ui/core/Typography";

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },
}));

export default function GridInfo(props) {
  const {
    boxesArray,
    boxXs,
    boxSm,
    textColor,
    borderColor,
    bgColor,
    imageSize,
    boxHeight,
  } = props.componentConfig;
  return (
    <Grid
      container
      direction="row"
      alignItems="flex-start"
      justify="space-between"
      style={{ backgroundColor: bgColor, padding: 20 }}
    >
      {boxesArray.map((box) => (
        <Grid item xs={boxXs} sm={boxSm} style={{ padding: 5 }}>
          <Box borderColor={borderColor} border={2} style={{ padding: 30 }}>
            <div
              style={{
                width: "100%",
                alignContent: "center",
                alignItems: "center",
              }}
            >
              <img
                src={box.image}
                style={{ display: "block", margin: "auto" }}
                alt={box.alt}
                height={imageSize}
              />
            </div>
            <Typography
              variant="h5"
              style={{ color: textColor, textAlign: "center", marginTop: 30 }}
            >
              {box.title}
            </Typography>

            <Typography
              style={{ color: textColor, textAlign: "justify", marginTop: 30 }}
            >
              {box.text}
            </Typography>
          </Box>
        </Grid>
      ))}
    </Grid>
  );
}
