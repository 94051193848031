export const Strings = {
  en: {
    businessName: "Smart Valet",
    slogan: "Smart Valet",
    account: {
      SesionView: {
        logIn: "Log in",
        registry: "Registry",
        closeSesion: "Log out",
      },
      Login: {
        title: "Log In",
        emailInput: "Email",
        passwordInput: "Password",
        logInButtom: "Log In",
        passwordRestore: "Restore Password",
        backToLogIn: "Back to Log In",
      },
      CreateAcount: {
        title: "Create Acount",
        nameInput: "Name",
        lastnameInput: "Last Name",
        phoneInput: "Phone",
        phoneError: "Invalid phone",
        emailInput: "Email",
        emailError: "Invalid e-mail",
        emailUsed: "E-mail used try to recover you password",
        passwordInput: "Password",
        passwordConfirmInput: "Password confirm",
        passwordConfirmError: "Error: not coincidence in two passwords",
        logInButtom: "Create Account",
        registryConfirmation: "Congratulation you already are log In",
      },
    },
    view: {
      requestButton: "Request Vehicle",
      scann: "Open Scann",
      closeScann: "Close Scanner",
      vehicleDetailTitle: "Vehicle Detail",
      startTime: "Start Time",
      poweredBy: "Powered By: ",
    },
    generalData: {
      required: "Required",
      businessSelectTitle: "Business",
      colors: {
        black: "Black",
        blue: "Blue",
        grey: "Grey",
        maroon: "Marron",
        red: "Red",
        white: "White",
        yellow: "Yellow",
        empty: "not defined",
        beige: "Beige",
        green: "Green",
        orange: "Orange",
        pink: "Pink",
      },
      status: {
        canceled: {
          description: "Canceled by authorize User",
          name: "Canceled",
        },
        parked: {
          description: "Car already parked",
          name: "Parked",
        },
        parking: {
          description: "Car going to one place",
          name: "Parking",
        },
        platform: {
          description: "Vehicle ready to be taken",
          name: "Platform ready to pick up",
        },
        recovering: {
          description: "Recovering vehicle",
          name: "Recovering",
        },
        requested: {
          description: "Vehicle requested by owner",
          name: "Requested",
        },
        scanned: {
          description: "Scanned",
          name: "Scanned",
        },
        taken: {
          description: "Vehicle already taken by owner",
          name: "Taken",
        },
        arriving: {
          description: "Vehicle Arriving",
          name: "Arriving",
        },
      },
      paymentStatus: {
        paid: {
          description: "Valet parking paid",
          name: "Paid",
        },
        unpaid: {
          description: "Pay pending",
          name: "Unpaid",
        },
      },
    },
  },
  es: {
    businessName: "Smart Valet",
    slogan: "Smart Valet",
    account: {
      SesionView: {
        logIn: "Inicia Sesión",
        registry: "Regístrate",
        closeSesion: "Cerrar Sesión",
      },
      Login: {
        title: "Inicia Sesión",
        emailInput: "Correo",
        passwordInput: "Contraseña",
        logInButtom: "Inicia sesión",
        passwordRestore: "Recuperar Contraseña",
        backToLogIn: "Regresar al inicio de sesión",
      },
      CreateAcount: {
        title: "Crear cuenta",
        nameInput: "Nombres",
        lastnameInput: "Apellidos",
        phoneInput: "Teléfono",
        phoneError: "Teéfono inválido",
        emailInput: "Correo",
        emailError: "Correo inválido",
        emailUsed: "Correo ya ustilizado intenta recuperar la contraseña",
        passwordInput: "Contraseña",
        passwordConfirmInput: "Confirma Contraseña",
        passwordConfirmError: "La confirmación de la contraseña no coincide",
        logInButtom: "Crear Cuenta",
        registryConfirmation:
          "Felicidades completaste exitosamente el registro.",
      },
    },
    view: {
      requestButton: "Solicitar vehículo",
      scann: "Abrir Scanner",
      closeScann: "Cerrar Scanner",
      vehicleDetailTitle: "Detalle del vehículo",
      startTime: "Hora de acceso",
      poweredBy: "Powered By: ",
    },

    generalData: {
      required: "Requerido",
      businessSelectTitle: "Negocio",
      colors: {
        black: "Negro",
        blue: "Azul",
        grey: "Gris",
        maroon: "Marron",
        red: "Rojo",
        white: "Blanco",
        yellow: "Amarillo",
        empty: "No definido",
        beige: "Beige",
        green: "Verde",
        orange: "Naranja",
        pink: "Rosado",
      },
      status: {
        canceled: {
          description: "Registro Cancelado por el usuario master",
          name: "Cancelado",
        },
        parked: {
          description: "Vehiculo unbicado en un puesto definido",
          name: "Estacionado",
        },
        parking: {
          description: "Vehiculo en la vía a un pusto definido",
          name: "Estacionando",
        },
        platform: {
          description: "Vehículo listo para la entrega a su dueño",
          name: "En Plataforma listo para entregar",
        },
        recovering: {
          description: "Vehiculo siendo recuperado",
          name: "Recuperando",
        },
        requested: {
          description: "Vehiculo solicitado por el propietario",
          name: "Solicitado",
        },
        scanned: {
          description: "Vehículo escameado",
          name: "Escaneado",
        },
        taken: {
          description: "Vehiculo entregado a su dueño",
          name: "Entregado",
        },
        arriving: {
          description: "Vehiculo Ingresando al valet sin escanear",
          name: "LLegando",
        },
      },
      paymentStatus: {
        paid: {
          description: "Servicio ya pagado",
          name: "Pagado",
        },
        unpaid: {
          description: "Pago pendiente",
          name: "No Pagado",
        },
      },
    },
  },
};
export const compareStrings = () => {
  var array = recorrerObjetoJsonLoopFind(Strings, "es", "en");
  if (array.length == 0) {
    return "NO HAY PENDIENTES POR AGREGAR AL IDIOMA INGLES";
  } else {
    console.error("Hay textos pendientes en el idioma INGLES");
    return array;
  }
};
export const recorrerObjetoJsonLoopFind = (objeto, langBase, langFind) => {
  var tab = 0;
  var langObjetcBase = objeto[langBase];
  var langObjetcFind = objeto[langFind];
  var Campos = [];
  recorrerObjetoJson(langObjetcBase, langObjetcFind);
  function recorrerObjetoJson(langObjetcBase, langObjetcFind) {
    for (var i in langObjetcBase) {
      if (
        langObjetcBase.hasOwnProperty(i) &&
        langObjetcFind.hasOwnProperty(i)
      ) {
        var valBase = langObjetcBase[i];
        var valFind = langObjetcFind[i];
        if (typeof valBase == "object") {
          recorrerObjetoJson(valBase, valFind);
        } else {
          // aquí si es atributo
          if (valFind == undefined) {
            Campos.push(JSON.stringify(langObjetcBase[i]));
          }
        }
      } else {
        Campos.push(i);
      }
    }
    return Campos;
  }
  return Campos;
};
