import * as types from "../actions/action-types";

const initialState = [];
export default function valetReducer(state = initialState, action) {
  switch (action.type) {
    case types.CATALOGUE:
      return Object.assign({}, state, {
        catalogue: action.catalogue,
      });

    default:
      return state;
  }
}
