import React, { Component } from "react";
import QrReader from "react-qr-reader";

export default class QrScanner extends Component {
  state = {
    result: "No result",
  };

  handleScan = (data) => {
    if (data) {
      this.props.callback(data);
      console.log(data);
      this.setState({
        result: data,
      });
    }
  };
  handleError = (err) => {
    console.error(err);
  };
  render() {
    if (!this.props.visible) {
      return <div></div>;
    }
    return (
      <div
        style={{ width: "100%", alignItems: "center", alignContent: "center" }}
      >
        <QrReader
          delay={300}
          onError={this.handleError}
          onScan={this.handleScan}
          style={{ height: "400px", width: "400px", margin: "auto" }}
        />
        {/* <p>{this.state.result}</p> */}
      </div>
    );
  }
}
