// Initialize Firebase
import * as firebase from "firebase";
import "firebase/firestore";

export const initializedFirebaseApp = firebase.initializeApp({
  apiKey: "AIzaSyCYQe9kXFeeN848WWcWW14lblPIypq2Mq4",
  authDomain: "softicade.firebaseapp.com",
  databaseURL: "https://softicade.firebaseio.com",
  projectId: "softicade",
  storageBucket: "softicade.appspot.com",
  messagingSenderId: "900907314699",
  appId: "1:900907314699:web:9ea49b9a7b18ca9c28e10e",
  measurementId: "G-CHDE6D234J",
});

export const analytics = firebase.analytics();

export const firestore = initializedFirebaseApp.firestore();

export const dbRef = initializedFirebaseApp.database();

export const auth = initializedFirebaseApp.auth();

export const users = "users";
export const catalogue = "catalogue";
export const business = "business";
export const businessUsers = "businessUsers";
export const usersList = "usersList";
export const businessInfo = "businessInfo";
export const locations = "locations";
export const locationConfig = "locationConfig";
export const locationData = "locationData";
export const locationsList = "locationsList";
export const vehicles = "vehicles";
export const incidents = "incidents";
export const activeUsers = "activeUsers";
export const locationsAuth = "locationsAuth";
