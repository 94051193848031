import * as types from "./action-types";
import {
  business,
  locations,
  locationsList,
  businessInfo,
  vehicles,
  incidents,
  activeUsers,
  firestore,
} from "../../firebase/firebasejs";
import { Strings } from "../../Strings";
import store from "../configureStore";
import { fecha } from "../../services/utils";
import axios from "axios";

//##########################################################################
export function updateVehicleAtribute(vehicle, changesObject) {
  // changesObject = {"status","scanned"};
  console.log("update Vehicle Status");
  const businessId = store.getState().valet.businessId;
  const locationId = store.getState().valet.locationId;
  const userID = store.getState().auth.userID;

  return (dispatch) => {
    if (businessId != undefined && locationId != undefined) {
      var newVehicleRef = firestore
        .collection(business)
        .doc(businessId)
        .collection(locations)
        .doc(locationId)
        .collection(vehicles)
        .doc(vehicle.id);

      for (var i in changesObject) {
        console.log(changesObject[i]);
        if (i === "status" && changesObject[i] === "parked") {
        } else if (
          i === "status" &&
          (changesObject[i] === "platform" || changesObject[i] === "taken")
        ) {
          // si el coche cambia a estatus platform o taken elimino su ocupación en el puesto que tenia
          changesObject.zone = "";
          changesObject.place = "";
        }
      }
      changesObject.changes = updateChangesList(
        vehicle,
        changesObject,
        "owner"
      );

      return newVehicleRef.set(changesObject, { merge: true }).then(() => {
        console.log("vehiculo ajustado");
        return "ok";
      });
    }
  };
}
//##########################################################################
export function getVehiclesRealTimeChanges(businessId, locationId, card) {
  var Ref = firestore
    .collection(business)
    .doc(businessId)
    .collection(locations)
    .doc(locationId)
    .collection(vehicles)
    .where("card", "==", card)
    .where("endStatus", "==", false);
  return (dispatch) => {
    return Ref.onSnapshot(function (snapshot) {
      if (snapshot == undefined) {
        dispatch({
          type: types.VEHICLE_ADD,
          vehicle: null,
          vehicleId: null,
        });
        return;
      }
      snapshot.docChanges().forEach(function (change) {
        if (change == undefined) {
          dispatch({
            type: types.VEHICLE_ADD,
            vehicle: null,
            vehicleId: null,
          });
          return;
        }
        if (change.type === "added") {
          var vehicleNew = { ...change.doc.data(), locationId: locationId };
          console.log("Vehicle create: ");
          dispatch({
            type: types.VEHICLE_ADD,
            vehicle: vehicleNew,
            vehicleId: change.doc.id,
          });
        }
        if (change.type === "modified") {
          console.log("Modified vehicle: ");
          var vehicleNew = { ...change.doc.data(), locationId: locationId };
          dispatch({
            type: types.VEHICLE_CHANGE,
            vehicle: vehicleNew,
            vehicleId: change.doc.id,
          });
        }
        if (change.type === "removed") {
          console.log("Removed vehicle: ");
          var vehicleNew = { ...change.doc.data(), locationId: locationId };
          dispatch({
            type: types.VEHICLE_REMOVE,
            vehicle: vehicleNew,
            vehicleId: change.doc.id,
          });
        }
      });
    });
  };
}
//##########################################################################
export function getVehicleRealTimeChanges(businessId, locationId, card) {
  return (dispatch) => {
    var params = {
      businessId: businessId,
      locationId: locationId,
      card: card,
    };
    var headers = {
      headers: { "Access-Control-Allow-Origin": true },
    };

    dispatch({
      type: types.LOADING,
      loading: true,
    });

    axios
      .post(
        "https://us-central1-softicade.cloudfunctions.net/getVehicleId",
        params,
        headers
      )
      .then((res) => {
        console.log(res);
        var Ref = firestore
          .collection(business)
          .doc(businessId)
          .collection(locations)
          .doc(locationId)
          .collection(vehicles)
          .doc(res.data.id);
        return Ref.onSnapshot(
          (doc) => {
            console.log(`Received doc snapshot: ${doc}`);
            const vehicle = {
              ...doc.data(),
              businessId: businessId,
              locationId: locationId,
              id: res.data.id,
            };
            dispatch({
              type: types.CURRENT_VEHICLE,
              currentVehicle: vehicle,
              currentVehicleId: res.data.id,
            });
            dispatch({
              type: types.LOADING,
              loading: false,
            });
          },
          (err) => {
            dispatch({
              type: types.LOADING,
              loading: false,
            });
            console.log(`Encountered error: ${err}`);
          }
        );
      })
      .catch((error) => {
        dispatch({
          type: types.LOADING,
          loading: false,
        });

        console.log(error);
      });
  };
}
//##########################################################################
export function getVehiclePayment(businessId, locationId, card) {
  return (dispatch) => {
    var params = {
      businessId: businessId,
      locationId: locationId,
      identifier: card,
      identifierType: "card",
    };
    console.log("params", params);
    var headers = {
      headers: {
        "Access-Control-Allow-Origin": true,
        Authorization:
          "Bearer EAAH7Uv9TiygBOyex2lLAaZCgvdmX5ZBtfVd730TDfnKdBZCSAyBrWchZBBf0aOHc1MnFDXmdXiXW4CQex0Yu3svEYE6CTBvUSvIsn4ECbAzN6rF0OyNWEetEyZCfyQEwuNd1U3tXC8ZCrgk10Nf3NRBwV3x5Sg7mNpjJIzXK2Kp3YF9T8vUpqcDE4RuXgAk2a4b0K6JTZASYMdJrsWZBMxXH2Mh2wlgtzuL78QZDZ1",
      },
    };

    dispatch({
      type: types.AMOUNT_TO_PAY,
      amountToPay: false,
      dateToPay: false,
      amountPayed: 0,
      statusPayment: 0,
    });

    axios
      .post(
        "https://us-central1-softicade.cloudfunctions.net/getTotalPayment",
        params,
        headers
      )
      .then((res) => {
        console.log(res.data);
        dispatch({
          type: types.AMOUNT_TO_PAY,
          amountToPay: res.data.amountToPay,
          dateToPay: res.data.date,
          amountPayed: 0,
          statusPayment: 0,
        });
      })
      .catch((error) => {
        dispatch({
          type: types.AMOUNT_TO_PAY,
          amountToPay: false,
          dateToPay: false,
          amountPayed: 0,
          statusPayment: 0,
        });

        console.log(error);
      });
  };
}
//##########################################################################
export function setAmountToPay(
  amountToPay,
  amountPayed,
  statusPayment,
  dateToPay
) {
  return (dispatch) => {
    dispatch({
      type: types.AMOUNT_TO_PAY,
      amountToPay: amountToPay,
      dateToPay: dateToPay,
      amountPayed: amountPayed,
      statusPayment: statusPayment,
    });
  };
}
//##########################################################################
export function requestVehicle(businessId, locationId, vehicleId) {
  return (dispatch) => {
    var params = {
      businessId: businessId,
      locationId: locationId,
      vehicleId: vehicleId,
    };
    var headers = {
      headers: { "Access-Control-Allow-Origin": true },
    };

    dispatch({
      type: types.LOADING,
      loading: true,
    });

    axios
      .post(
        "https://us-central1-softicade.cloudfunctions.net/requestVehicle",
        params,
        headers
      )
      .then(
        (res) => {
          console.log(`Vehículo solicitado`);
          dispatch({
            type: types.LOADING,
            loading: false,
          });
        },
        (err) => {
          console.log(`Encountered error: ${err}`);
          dispatch({
            type: types.LOADING,
            loading: false,
          });
        }
      )
      .catch((error) => {
        dispatch({
          type: types.LOADING,
          loading: false,
        });

        console.log(error);
      });
  };
}
//##########################################################################
export function sendPayment(businessId, locationId, vehicleId) {
  return (dispatch) => {
    var params = {
      businessId: businessId,
      locationId: locationId,
      vehicleId: vehicleId,
    };
    var headers = {
      headers: { "Access-Control-Allow-Origin": true },
    };

    dispatch({
      type: types.LOADING,
      loading: true,
    });

    axios
      .post(
        "https://us-central1-softicade.cloudfunctions.net/setPagueloFacilPayment",
        params,
        headers
      )
      .then(
        (res) => {
          console.log(`Vehículo pagado`, res);
          dispatch({
            type: types.LOADING,
            loading: false,
          });
        },
        (err) => {
          console.log(`Encountered error: ${err}`);
          dispatch({
            type: types.LOADING,
            loading: false,
          });
        }
      )
      .catch((error) => {
        dispatch({
          type: types.LOADING,
          loading: false,
        });

        console.log(error);
      });
  };
}
//##########################################################################

export function updateChangesList(vehicle, changeObject, userID) {
  let changes = vehicle.changes;
  const atributesToSaveChanges = [
    "card",
    "key",
    "brand",
    "color",
    "plateNumber",
    "status",
    "place",
    "zone",
    "closed",
  ];
  const atributesToAdd = ["notes", "photos"];

  for (var i in changeObject) {
    if (atributesToSaveChanges.indexOf(i) >= 0) {
      var date = Math.round(Date.now() / 1000);
      var ramdom = Math.round(Math.random() * 100);
      changes[date + "-" + ramdom] = {
        date: date,
        userID: userID,
        atribute: i,
        previous: vehicle[i] == undefined ? "" : vehicle[i],
        newValue: changeObject[i],
      };
    } else if (atributesToAdd.indexOf(i) >= 0) {
      var date = Math.round(Date.now() / 1000);
      var ramdom = Math.round(Math.random() * 100);
      changes[date + "-" + ramdom] = {
        date: date,
        userID: userID,
        atribute: i,
        previous: "",
        newValue: i + " ADD",
      };
    } else if (i == "payment") {
      var date = Math.round(Date.now() / 1000);
      var ramdom = Math.round(Math.random() * 100);
      var previous = Object.values(vehicle[i].list);
      var newValue = Object.values(changeObject[i].list);
      var add = "ADD";
      if (previous.length == newValue.length) {
        add = "CHANGE";
      }
      changes[date + "-" + ramdom] = {
        date: date,
        userID: userID,
        atribute: i,
        previous: "",
        newValue: i + " " + add,
      };
    }
  }
  return changes;
}

//##########################################################################
export function saveIncident(businessId, locationId, incident) {
  console.log("save incident Data");
  return (dispatch) => {
    if (businessId != undefined && locationId != undefined) {
      var newVehicleRef = firestore
        .collection(business)
        .doc(businessId)
        .collection(locations)
        .doc(locationId)
        .collection(incidents)
        .doc();

      return newVehicleRef.set(incident).then(() => {
        console.log("incident agregado");
        return "ok";
      });
    }
  };
}
