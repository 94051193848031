import React, { useState } from "react";
import { connect, useDispatch, useSelector } from "react-redux";

import { useForm, Controller } from "react-hook-form";
import { makeStyles } from "@material-ui/core/styles";
import {
  TextField,
  InputAdornment,
  IconButton,
  Typography,
  Button,
  Grid,
} from "@material-ui/core";
import { createContactInfo } from "../../data-store/actions/valet-actions";
import SimpleDialogOpen from "../LayoutContainers/SimpleDialogOpen";
const useStyles = makeStyles({});

export default function ContactConfirm(props) {
  const {
    fieldObject,
    inputColor,
    buttonText,
    buttonColor,
    buttonTextColor,
  } = props;
  const classes = useStyles();
  return (
    <Grid
      container
      direction="row"
      justify="space-between"
      style={{
        backgroundColor: "#1D2242",
        height: "400px",
        width: "400px",
        padding: "40px",
      }}
    >
      <Grid item xs={12} sm={12}>
        <Typography
          variant="h5"
          style={{ color: "white", textAlign: "justify" }}
        >
          Gracias por contactarnos, pronto nos pondremos en contacto contigo
        </Typography>
      </Grid>
    </Grid>
  );
}
