import * as types from "./action-types";
import {
  firestore,
  storage,
  users,
  business,
  businessUsers,
  locations,
  activeUsers,
} from "../../firebase/firebasejs";
import * as firebase from "firebase";
import { Strings } from "../../Strings";
import store from "../configureStore";
import { getLocationsVehiclesRealTimeChanges } from "./vehicle-actions";
import axios from "axios";

//###########################################################################
export function siteConfig() {
  return (dispatch) => {
    // const viewsConfig = fb.dbRef.ref("viewsConfig");
    // return viewsConfig.on("value", function (snapshot) {
    //   console.log("snapshot", snapshot.val());
    //   dispatch({
    //     type: types.SITE_CONFIG,
    //     siteConfig: snapshot.val(),
    //   });
    // });
  };
}
//###########################################################################

export function createContactInfo(data) {
  return (dispatch) => {
    return firestore
      .collection("contactInfo")
      .doc()
      .set({ ...data, date: firebase.firestore.FieldValue.serverTimestamp() })
      .then(() => {
        console.log("Info enviada");
        return "ok";
      });
  };
}
