import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import Grid from "@material-ui/core/Grid";
//import { useTracking } from "react-tracking";
import Typography from "@material-ui/core/Typography";
import ContactForm from "../../../ConctactForm/ContactFormFullData";

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },
}));

export default function HeaderLanding(props) {
  const {
    logo,
    title,
    text,
    image,
    bgColor,
    imageHeight,
    buttonColor,
    buttonTextColor,
  } = props.componentConfig;
  return (
    <Grid
      container
      direction="row"
      style={{
        backgroundColor: bgColor,
        width: "100%",
        padding: "40px",
        paddingTop: 100,
      }}
    >
      <Grid item xs={12} sm={7} style={{ paddingTop: 50, paddingLeft: "20px" }}>
        <div
          style={{ width: "100%", alignContent: "left", alignItems: "left" }}
        >
          <img
            src={logo}
            style={{ display: "block", margin: "0" }}
            alt={title}
            height="100px"
          />
        </div>
        <Typography
          variant="h3"
          style={{ color: "white", textAlign: "left", marginTop: 30 }}
        >
          {title}
        </Typography>
        <Typography
          variant="subtitle1"
          style={{ color: "white", textAlign: "justify", marginTop: 30 }}
        >
          {text}
        </Typography>
        <ContactForm
          fieldObject={[
            {
              label: "Nombre",
              errorMessage: "Campo Requerido",
              fieldName: "name",
              type: "text",
              defaultValue: "",
              required: true,
              regex: "",
              textSize: "h6",
            },
            {
              label: "Nombre de la empresa",
              errorMessage: "Campo Requerido",
              fieldName: "business",
              type: "text",
              defaultValue: "",
              required: true,
              regex: "",
              textSize: "h6",
            },
            {
              label: "Correo",
              errorMessage: "Campo Requerido",
              fieldName: "email",
              type: "text",
              defaultValue: "",
              required: true,
              regex: "",
              textSize: "h6",
            },
            {
              label: "Teléfono",
              errorMessage: "Campo Requerido",
              fieldName: "phone",
              type: "text",
              defaultValue: "",
              required: true,
              regex: "",
              textSize: "h6",
            },
            {
              label: "Que necesitas?",
              errorMessage: "Campo Requerido",
              fieldName: "need",
              type: "text",
              defaultValue: "",
              required: true,
              regex: "",
              textSize: "h6",
            },
          ]}
          buttonText="Enviar Información a Smart Valet"
          buttonColor={buttonColor}
          buttonTextColor={buttonTextColor}
          inputColor="#FFFFFF"
          direcionn="row"
        />
      </Grid>
      <Grid item xs={12} sm={5}>
        <img src={image} alt={title} height={imageHeight} />
      </Grid>
    </Grid>
  );
}
