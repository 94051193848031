import React from "react";
import { useSelector } from "react-redux";
import {
  createMuiTheme,
  makeStyles,
  ThemeProvider,
} from "@material-ui/core/styles";
import Grid from "@material-ui/core/Grid";
import Banner from "../pages/sections/Banner/Banner";
import HeaderLanding from "../pages/sections/HeaderLanding/HeaderLanding";
import Image from "../pages/sections/Image/Image";
import Text from "../pages/sections/Text/Text";
import Title from "../pages/sections/Title/Title";
import GridBoxInfo from "../pages/sections/GridBoxInfo/GridBoxInfo";
import GridBoxPrice from "../pages/sections/GridBoxPrice/GridBoxPrice";
import GridInfo from "../pages/sections/GridInfo/GridInfo";
import Footer from "../pages/sections/Footer/Footer";
import RequestVehicle from "../pages/sections/RequestVehicle/RequestVehicle";
import PaymentContainer from "../pages/sections/Payment/PaymentContainer";
import { Box, CircularProgress } from "@material-ui/core";
import Loading from "./Loading";

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },
}));

const Components = {
  banner: Banner,
  headerLanding: HeaderLanding,
  image: Image,
  text: Text,
  title: Title,
  gridBoxInfo: GridBoxInfo,
  gridBoxPrice: GridBoxPrice,
  gridInfo: GridInfo,
  footer: Footer,
  requestVehicle: RequestVehicle,
  paymentContainer: PaymentContainer,
};

export default function LayoutResolver({ page }) {
  const classes = useStyles();
  const siteConfig = useSelector((state) => state.valet.siteConfig);
  const loading = useSelector((state) => state.auth.loading);
  const defaultWidth = { xs: 12, sm: 6 };
  if (page) {
    const innerComponents = (
      <div className={classes.root}>
        {/* {loading && (
          <div
            style={{
              position: "absolute",
              width: "100%",
              height: 3000,
              zIndex: "1000",
            }}
          >
            <Loading />
          </div>
        )}{" "} */}
        <Grid
          container
          direction="row"
          alignItems="center"
          style={{
            width: "100%",
            textAlign: "center",
            backgroundRepeat: "repeat",
            backgroundPosition: "left top",
            backgroundSize: "100% auto",
            backgroundImage: `url(${page.backgroundImage})`,
          }}
        >
          {Object.values(page.views).map((item, i) => {
            const SpecificComponent = Components[item.component];
            return (
              <Grid
                key={i}
                item
                xs={
                  item.componentConfig.width && item.componentConfig.width.xs
                    ? item.componentConfig.width.xs
                    : defaultWidth.xs
                }
                sm={
                  item.componentConfig.width && item.componentConfig.width.sm
                    ? item.componentConfig.width.sm
                    : defaultWidth.sm
                }
                style={{
                  width: "100%",
                  padding: item.componentConfig.padding
                    ? item.componentConfig.padding
                    : 0,
                }}
              >
                {SpecificComponent ? (
                  <SpecificComponent componentConfig={item.componentConfig} />
                ) : null}
              </Grid>
            );
          })}
        </Grid>
      </div>
    );

    if (page.miniTheme) {
      console.log("minTheme", page.miniTheme);
      const { miniTheme } = page;
      return (
        <ThemeProvider
          theme={(theme) => {
            const primaryColor =
              miniTheme.primary || theme.palette.primary.main;
            const secondaryColor =
              miniTheme.secondary || theme.palette.secondary.main;
            const textPrimaryColor =
              miniTheme.text || theme.palette.text.primary || "#000";
            const backgroundColor =
              miniTheme.background ||
              theme.palette.background.default ||
              "#000";

            return createMuiTheme({
              ...theme,
              palette: {
                ...theme.palette,
                background: {
                  default: backgroundColor,
                },
                primary: {
                  main: primaryColor,
                },
                secondary: {
                  main: secondaryColor,
                },
                text: {
                  primary: textPrimaryColor,
                },
              },
            });
          }}
        >
          <Box bgcolor="background.default">{innerComponents}</Box>
        </ThemeProvider>
      );
    }

    return innerComponents;
  }

  return null;
}
