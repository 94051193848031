import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import Grid from "@material-ui/core/Grid";
import { connect, dispatch, useSelector } from "react-redux";
//import { useTracking } from "react-tracking";
import Typography from "@material-ui/core/Typography";

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },
}));

export default function Text(props) {
  const {
    title,
    textArray,
    position,
    bgColor,
    textColor,
  } = props.componentConfig;
  return (
    <Grid container direction="row" style={{ backgroundColor: bgColor }}>
      <Grid item xs={12}>
        <Typography
          variant="h3"
          style={{
            color: textColor,
            textAlign: position || "justify",
            marginTop: 30,
          }}
        >
          {title}
        </Typography>
        <Typography
          variant="subtitle1"
          style={{
            color: textColor,
            textAlign: position || "justify",
            marginTop: 30,
          }}
        >
          {textArray && textArray.map((dato) => <p>{dato}</p>)}
        </Typography>
      </Grid>
    </Grid>
  );
}
