import * as types from "../actions/action-types";

const initialState = [];
export default function valetReducer(state = initialState, action) {
  switch (action.type) {
    case types.USER_ACCOUNT:
      return Object.assign({}, state, {
        userAccount: action.userAccount,
        userID: action.userID,
      });
    case types.LANGUAGE:
      return Object.assign({}, state, {
        language: action.language,
        languageObject: action.languageObject,
      });
    case types.LOADING:
      return Object.assign({}, state, {
        loading: action.loading,
      });
    case types.CATALOGUE:
      return Object.assign({}, state, {
        catalogue: action.catalogue,
      });

    default:
      return state;
  }
}
