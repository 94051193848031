import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import Grid from "@material-ui/core/Grid";
import { connect, dispatch, useSelector } from "react-redux";
//import { useTracking } from "react-tracking";
import Typography from "@material-ui/core/Typography";

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },
}));

export default function Image(props) {
  const { image, alt, bgColor } = props.componentConfig;
  return (
    <Grid container direction="row" style={{ backgroundColor: bgColor }}>
      <Grid item xs={12}>
        <img src={image} alt={alt} width="100%" />
      </Grid>
    </Grid>
  );
}
