import * as types from "../actions/action-types";

const initialState = [];
export default function valetReducer(state = initialState, action) {
  switch (action.type) {
    case types.VEHICLE_ADD:
      var vehicleId = action.vehicleId;
      var vehicle = action.vehicle;
      vehicle.id = vehicleId;
      return Object.assign({}, state, {
        vehicles: Object.assign({}, state.vehicles, {
          [vehicleId]: vehicle,
        }),
      });
    case types.VEHICLE_CHANGE:
      var vehicleId = action.vehicleId;
      var vehicle = action.vehicle;
      vehicle.id = vehicleId;
      return Object.assign({}, state, {
        vehicles: Object.assign({}, state.vehicles, {
          [vehicleId]: vehicle,
        }),
      });
    case types.VEHICLE_REMOVE:
      var vehicleId = action.vehicleId;
      var vehiclesNew = { ...state.vehicles };
      delete vehiclesNew[vehicleId];
      return Object.assign({}, state, {
        vehicles: vehiclesNew,
      });
    case types.CURRENT_VEHICLE:
      return Object.assign({}, state, {
        currentVehicle: action.currentVehicle,
        currentVehicleId: action.currentVehicleId,
      });
    case types.AMOUNT_TO_PAY:
      return Object.assign({}, state, {
        amountToPay: action.amountToPay,
        dateToPay: action.dateToPay,
        amountPayed: action.amountPayed,
        statusPayment: action.statusPayment,
      });

    default:
      return state;
  }
}
