export const USER_ACCOUNT = "USER_ACCOUNT";
export const LANGUAGE = "LANGUAGE";
export const LANGUAGE_OBJECT = "LANGUAGE_OBJECT";

export const VEHICLE_ADD = "VEHICLE_ADD";
export const VEHICLE_CHANGE = "VEHICLE_CHANGE";
export const VEHICLE_REMOVE = "VEHICLE_REMOVE";
export const CATALOGUE = "CATALOGUE";
export const CURRENT_VEHICLE = "CURRENT_VEHICLE";

export const LOADING = "LOADING";
export const AMOUNT_TO_PAY = "AMOUNT_TO_PAY";
