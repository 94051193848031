import React, { useState } from "react";
import { connect, useDispatch, useSelector } from "react-redux";

import { useForm, Controller } from "react-hook-form";
import { makeStyles } from "@material-ui/core/styles";
import {
  TextField,
  InputAdornment,
  IconButton,
  Typography,
  Button,
  Grid,
} from "@material-ui/core";
import { createContactInfo } from "../../data-store/actions/valet-actions";
import SimpleDialogOpen from "../LayoutContainers/SimpleDialogOpen";
import ContactConfirm from "./ContactConfirm";
import SimpleDialogDemo from "../LayoutContainers/SimpleDialog";
const useStyles = makeStyles({});

export default function ContactFormFullData(props) {
  const {
    fieldObject,
    inputColor,
    buttonText,
    buttonColor,
    buttonTextColor,
  } = props;
  const {
    fieldName,
    label,
    errorMessage,
    defaultValue,
    required,
    textSize,
    min,
    max,
  } = fieldObject;
  const classes = useStyles();
  const { register, handleSubmit, errors, reset } = useForm();
  const [editMode, setEditMode] = useState(false);
  const [open, setOpen] = React.useState(false);

  const dispatch = useDispatch();

  function onSubmit(values, e) {
    dispatch(createContactInfo(values));
    setOpen(true);
    reset();
  }

  function callback(value) {
    setOpen(value);
  }

  return (
    <div>
      <SimpleDialogDemo
        buttonText={"Contáctanos"}
        colorButton={"#36a9e1"}
        fontSizeButtom={"20px"}
        borderRadius={"50px"}
      >
        <Grid
          container
          direction="row"
          justify="space-between"
          style={{
            margin: "30px",
            color: buttonColor,
            borderRadius: "50px",
            height: "500px",
            width: "500px",
          }}
        >
          <Grid item xs={12} sm={12}>
            <Typography
              variant="h4"
              style={{ color: "white", textAlign: "center" }}
            >
              Información de contacto
            </Typography>
          </Grid>

          <form
            noValidate
            onSubmit={handleSubmit(onSubmit)}
            style={{ width: "100%" }}
          >
            {fieldObject.map((i) => (
              <Grid
                item
                xs={12}
                sm={12}
                style={{
                  backgroundColor: inputColor,
                  margin: "5px",
                }}
              >
                <TextField
                  color="secondary"
                  style={{
                    color: buttonColor,
                    margin: "5px",
                  }}
                  fullWidth
                  label={i.label}
                  disabled={false}
                  helperText={errors[i.fieldName] ? i.errorMessage : null}
                  error={Boolean(errors[i.fieldName])}
                  defaultValue={i.defaultValue}
                  inputRef={register({ required: i.required })}
                  name={i.fieldName}
                  type="text"
                  InputLabelProps={{
                    style: { fontSize: 15, verticalAlign: "center" },
                  }}
                />
              </Grid>
            ))}
            <Grid item xs={12} sm={12}>
              <Button
                onClick={handleSubmit(onSubmit)}
                type="submit"
                variant="contained"
                style={{
                  marginRight: "-100px",
                  height: "50px",
                  borderRadius: "50px",
                  backgroundColor: buttonColor,
                  color: buttonTextColor,
                  paddingLeft: "20px",
                  paddingRight: "20px",
                  width: "100%",
                }}
              >
                {buttonText}
              </Button>
            </Grid>
          </form>
        </Grid>
      </SimpleDialogDemo>

      <SimpleDialogOpen openProp={open} callback={callback}>
        <ContactConfirm />
      </SimpleDialogOpen>
    </div>
  );
}
