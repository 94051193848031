import React from "react";
import { Provider } from "react-redux";
import track from "react-tracking";
import { InitTracking, TrakingData } from "./useTracking";

import PropTypes from "prop-types";
import Container from "@material-ui/core/Container";
import { ThemeProvider } from "@material-ui/styles";
import { createMuiTheme } from "@material-ui/core/styles";
import CssBaseline from "@material-ui/core/CssBaseline";
import { compareStrings } from "./Strings";

import "./App.css";
import Root from "./Root";

import { BrowserRouter } from "react-router-dom";

import { userAccount, setLanguage } from "./data-store/actions/auth-actions";

const theme = createMuiTheme({
  typography: {
    fontFamily: "Poppins-Regular,Helvetica, Arial, sans-serif",
    fontSize: 14,
    fontWeightLight: 400,
    fontWeightRegular: 700,
    fontWeightMedium: 500,
    color: "#000000",
  },

  palette: {
    primary: {
      // light: will be calculated from palette.primary.main,
      main: "#1d2242",
      // dark: will be calculated from palette.primary.main,
      // contrastText: will be calculated to contrast with palette.primary.main
    },
    secondary: {
      light: "#0066ff",
      main: "#36a9e1",
      // dark: will be calculated from palette.secondary.main,
      contrastText: "#ffcc00",
    },
    // error: will use the default color
  },
  spacing: 4,
});

InitTracking();

const App = ({ store }) => {
  //store.dispatch(userAccount());

  var localLang = localStorage.getItem("language");
  console.log(localLang);
  console.log(localStorage);
  if (localLang == null) {
    store.dispatch(setLanguage("es"));
  } else {
    store.dispatch(setLanguage(localStorage.getItem("language")));
  }

  const element = (
    <Provider store={store}>
      <BrowserRouter>
        <ThemeProvider theme={theme}>
          <Root />
        </ThemeProvider>
      </BrowserRouter>
    </Provider>
  );

  return element;
};

App.propTypes = {
  store: PropTypes.object.isRequired,
};

const TrackedApp = track(
  // app-level tracking data
  { app: "smart-valet-public" },

  // top-level options
  {
    // custom dispatch to console.log in addition to pushing to dataLayer[]
    dispatch: (data) => {
      TrakingData(data);
    },
  }
)(App);

export default TrackedApp;
