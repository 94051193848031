//import ReactGA from 'react-ga';
import ReactPixel from "react-facebook-pixel";
import { analytics } from "./firebase/firebasejs";

export function InitTracking() {
  const advancedMatching = { em: "some@email.com" }; // optional, more info: https://developers.facebook.com/docs/facebook-pixel/advanced/advanced-matching
  const options = {
    autoConfig: true, // set pixel's autoConfig
    debug: false, // enable logs
  };
  ReactPixel.init("XXXXX", advancedMatching, options);
  // analitics de firebase iniciado en firebase file;
}

export function TrakingData(data) {
  console.log(data);

  (window.dataLayer = window.dataLayer || []).push(data);
  //google analytics
  analytics.logEvent(data.event.event, {
    content_type: "image",
    content_id: "P12453",
    items: [{ name: "Kittens" }],
  });
  // facebook pixel
  ReactPixel.pageView();
  //ReactPixel.track("event", "data"); // For tracking default events, more info about events and data https://developers.facebook.com/docs/ads-for-websites/pixel-events/v2.9
  //ReactPixel.trackSingle('PixelId', event, data); // For tracking default events, more info about events and data https://developers.facebook.com/docs/ads-for-websites/pixel-events/v2.9
  //ReactPixel.trackCustom(event, data); // For tracking custom events
  //ReactPixel.trackSingleCustom('PixelId', event, data); // For tracking custom events
}

//####################################################################################
function endClientTrack(eventoIndex) {
  if (gaIdEvento != undefined && gaIdEvento != "") {
    window[`ga-disable-${gaIdEvento}`] = true;
  }
  if (pixelIdEvento != undefined && pixelIdEvento != "") {
    fbq.pixelIdEvento = null;
  }
}
//####################################################################################
var fbq = null;
var ga = null;
var EventoIndex = null;
var EventosData = null;

var gaIdEvento = undefined;
var pixelIdEvento = undefined;
//####################################################################################
function initClientTrack(eventoIndex) {
  console.log("Iniciando el pixel y id de analytics especificos del evento");

  // aqui seteo la varialbe global con el GAID del evento
  gaIdEvento = EventosData[EventoIndex].gaIdEvento;
  if (gaIdEvento != undefined && gaIdEvento != "") {
    console.log("gaide Creado");
    ga("create", gaIdEvento, { name: eventoIndex });
  }

  // aqui seteo la varialbe global con el Pixel especifico del evento
  pixelIdEvento = EventosData[EventoIndex].pixelIdEvento;
  if (pixelIdEvento != undefined && pixelIdEvento != "") {
    console.log("pixel definido " + pixelIdEvento);
    if (!fbq.pixelIdEvento) {
      console.log(" iniciado");
      fbq("init", pixelIdEvento);
    } else {
      console.log(" already init");
    }
  }
}
//####################################################################################
function userTrack(eventoIndex, typeEvent, objectParam) {
  console.log(
    "Tracking evento espedifico para todos los pixeles y ids de analytics del evento"
  );

  // objectParam debe contener la el atributo Label obligatorios, va a servir para saber que pagina vio.
  // puede tener la orden de compra del usuario para calcualr el total y contabilizar la campaña.
  // y el atributo currency para enviar la moneda

  var objectParam =
    objectParam == undefined || objectParam.label == undefined
      ? { label: "vacio" }
      : objectParam;

  var typeEventArray = {
    pixel: {
      PageView: "PageView",
      InitiateCheckout: "InitiateCheckout",
      Purchase: "Purchase",
      PurchaseFail: "PurchaseFail",
      ViewContent: "ViewContent",
      Lead: "Lead",
      customTrack: {
        PurchaseFail: true,
      },
    },
    ga: {
      PageView: "pageview",
      InitiateCheckout: "InitiateCheckout",
      Purchase: "Purchase",
      PurchaseFail: "PurchaseFail",
      ViewContent: "ViewContent",
      Lead: "Lead",
    },
    descripcionTypeEvent: {
      PageView: "Para indicar que el usuario entró a ver esa página",
      InitiateCheckout:
        "Cuando el usuario completo sus ddatos y los del evento y pasa a la vista de pago",
      Purchase: "Cuando llega a la vista de confirmación",
      ViewContent: "Cuando ve la pagina, lo voy a usar para la convocatoria",
      Lead: "Cuando presiona el link de inscripciones y llena sus datos",
    },
  };

  console.log(
    "traking - " +
      typeEvent +
      " - " +
      typeEventArray.descripcionTypeEvent[typeEvent]
  );
  // facebook pixel track
  if (typeEventArray.pixel[typeEvent] != undefined) {
    pixelTrack(
      eventoIndex,
      typeEventArray.pixel[typeEvent],
      objectParam,
      typeEventArray.pixel.customTrack[typeEvent]
    );
  } else {
    console.log(typeEvent + " No definido para el pixel");
  }

  // analytics pixel track
  if (typeEventArray.ga[typeEvent] != undefined) {
    googleAnalyticsTrack(
      eventoIndex,
      typeEventArray.ga[typeEvent],
      objectParam
    );
  } else {
    console.log(typeEvent + " No definido para GA");
  }
}
//####################################################################################
function googleAnalyticsTrack(eventoIndex, typeEvent, objectParam) {
  console.log("GATrack");
  var dashportGAId = "UA-38416117-1";

  // tracking para dashport
  ga("dashport.send", {
    hitType: "event",
    eventCategory: eventoIndex,
    eventAction: typeEvent,
    eventLabel: objectParam.label,
  });
  // tracking para el evento
  if (gaIdEvento != undefined && gaIdEvento != "") {
    ga(`${eventoIndex}.send`, {
      hitType: "event",
      eventCategory: eventoIndex,
      eventAction: typeEvent,
      eventLabel: objectParam.label,
    });
  }
}
//####################################################################################
function pixelTrack(eventoIndex, typeEvent, objectParam, customTrack) {
  console.log("pixelTrack");
  if (typeEvent == "Purchase" || typeEvent == "InitiateCheckout") {
    // recibo la orden de la compra
    var value = 0;
    var order = objectParam.order;
    var contents = [];
    for (var i in order) {
      value = value + order[i].quantity * order[i].unit_price;
      contents.push({
        id: order[i].code,
        quantity: order[i].quantity,
      });
    }

    objectParam.value = value;
    objectParam.contents = contents;
    objectParam.content_ids = eventoIndex;
  } else {
    objectParam.content_ids = eventoIndex;
  }

  var trackType = customTrack ? "trackSingleCustom" : "trackSingle";
  // facebook pixel track
  var dashportPixelId = "274911903368027";
  if (objectParam == null) {
    fbq(trackType, dashportPixelId, typeEvent);
  } else {
    fbq(trackType, dashportPixelId, typeEvent, objectParam);
  }
  if (pixelIdEvento != undefined && pixelIdEvento != "") {
    if (objectParam == null) {
      fbq(trackType, pixelIdEvento, typeEvent);
    } else {
      fbq(trackType, pixelIdEvento, typeEvent, objectParam);
    }
  }
}
