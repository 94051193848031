import React, { useEffect, useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import Grid from "@material-ui/core/Grid";
import { connect, useSelector, useDispatch } from "react-redux";
//import { useTracking } from "react-tracking";
import Typography from "@material-ui/core/Typography";
import {
  requestVehicle,
  saveIncident,
  sendPayment,
} from "../../../../data-store/actions/vehicle-actions";
import { Button, Paper } from "@material-ui/core";
import {
  useGetBrandName,
  useGetColorName,
} from "../../../utilsAuth/VehicleUtils";
import { fechahora } from "../../../../services/utils";
import { useLocation } from "react-router-dom";
const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },
}));

export default function VehicleDetail(props) {
  const { textColor, bgColor } = props;
  const dispatch = useDispatch();

  const [fakeRequest, setFakeRequest] = useState(false);

  const catalogue = useSelector((state) => state.auth.catalogue);
  const currentVehicle = useSelector((state) => state.vehicle.currentVehicle);
  const languageObject = useSelector((state) => state.auth.languageObject);
  const texts = languageObject.view;

  function useQuery() {
    return new URLSearchParams(useLocation().search);
  }

  const query = useQuery();

  const businessId = query.get("business");
  const locationId = query.get("location");
  const card = query.get("card");

  useEffect(() => {}, []);

  function requestVehicleFunc() {
    dispatch(
      requestVehicle(
        currentVehicle.businessId,
        currentVehicle.locationId,
        currentVehicle.id
      )
    );
  }
  // function sendPaymentFunc() {
  //   dispatch(
  //     sendPayment(
  //       currentVehicle.businessId,
  //       currentVehicle.locationId,
  //       currentVehicle.id
  //     )
  //   );
  // }

  function saveIncidentFunc() {
    setFakeRequest(true);
    const incident = {
      incident: "Vehículo solicitado no registrado Tarjeta :" + card,
      date: new Date(),
      userId: "public",
    };
    dispatch(saveIncident(businessId, locationId, incident));
  }
  if (currentVehicle === undefined && card === null) {
    return <div style={{ minHeight: "300px" }}></div>;
  }

  return (
    <Grid container direction="row">
      <Grid item xs={12} sm={12}>
        <Typography
          variant="h5"
          style={{ color: textColor, textAlign: "center", marginTop: 30 }}
        >
          Estatus del vehículo
        </Typography>
        <Typography
          variant="h3"
          style={{ color: textColor, textAlign: "center", marginTop: 30 }}
        >
          {currentVehicle &&
            currentVehicle.status !== "taken" &&
            languageObject.generalData.status[currentVehicle.status].name}
        </Typography>
      </Grid>

      <Grid
        item
        xs={12}
        sm={12}
        style={{ paddingTop: 50, paddingLeft: "20px" }}
      >
        {currentVehicle &&
          (currentVehicle.status === "scanned" ||
            currentVehicle.status === "parked" ||
            currentVehicle.status === "parking" ||
            currentVehicle.status === "scanned") && (
            <Button
              variant="contained"
              color="primary"
              style={{ fontSize: 30, margin: 30 }}
              onClick={() => requestVehicleFunc()}
            >
              Solicitar
            </Button>
          )}
        {/* {currentVehicle &&
          (currentVehicle.status === "scanned" ||
            currentVehicle.status === "parked" ||
            currentVehicle.status === "parking" ||
            currentVehicle.status === "scanned") && (
            <Button
              variant="contained"
              color="primary"
              style={{ fontSize: 30, margin: 30 }}
              onClick={() => sendPaymentFunc()}
            >
              enviar pago
            </Button>
          )} */}

        {(!currentVehicle || currentVehicle.status === "taken") &&
          card !== null && (
            <Button
              variant="contained"
              color="primary"
              disabled={fakeRequest}
              style={{ fontSize: 30, margin: 30 }}
              onClick={() => saveIncidentFunc()}
            >
              {fakeRequest
                ? "Error de conexion, favor de acudir directamente al valet"
                : "Solicitar *"}
            </Button>
          )}

        <Typography
          variant="h5"
          style={{ color: textColor, textAlign: "center", marginTop: 10 }}
        >
          {texts.vehicleDetailTitle}
        </Typography>
        <Typography
          variant="h3"
          style={{ color: textColor, textAlign: "center", marginTop: 10 }}
        >
          {currentVehicle &&
            catalogue &&
            currentVehicle.brand !== "empty" &&
            useGetBrandName(currentVehicle.brand, catalogue.cars)}{" "}
          {currentVehicle &&
            languageObject &&
            useGetColorName(currentVehicle.color, languageObject)}
        </Typography>
        <Typography
          variant="h5"
          style={{ color: textColor, textAlign: "center", marginTop: 10 }}
        >
          {texts.startTime} {" : "}
          {currentVehicle && fechahora(currentVehicle.startTime.toDate())}{" "}
        </Typography>
      </Grid>
    </Grid>
  );
}
